import { t } from '@app/utils'

// IE z-index is broken by translateY
// export const ANIM_CSS = { transform: 'translateY(-5px)', opacity: '0' };
export const ANIM_CSS = { opacity: '0' }
export const ANIM_EASING = 'ease-in-out'
export const ANIM_DURATION = 500
export const MANDATORY_THEMES = [5, 15, 16, 99]

// export const CUSTOMERS_COMPANY_PROVISIONNING = ['cerba', 'auchan'];
// export const CUSTOMERS_WHYTE_BRAND = ['cerba'];
export const CUSTOMERS = {
    betterise: {
        title: 'Betterise Connect',
        description: 'Betterise Connect description',
        i18ndescription: t('Betterise Connect description'),
        mailto: 'alfred@betterise.me',
        appurl: {
            ios: 'https://itunes.apple.com/fr/app/betterise-le-majordome-de-votre-sant%C3%A9/id916264011?mt=8',
            android:
                'https://play.google.com/store/apps/details?id=com.betterise.android',
        },
    },
}

export const FREQUENCY = {
    0: {
        key: '1',
        num: '',
        value: '1 fois par jour',
        i18nvalue: t('1 fois par jour'),
    },
    1: {
        key: '0.857',
        num: '6 ',
        value: 'fois par semaine',
        i18nvalue: t('fois par semaine'),
    },
    2: {
        key: '0.714',
        num: '5 ',
        value: 'fois par semaine',
        i18nvalue: t('fois par semaine'),
    },
    3: {
        key: '0.571',
        num: '4 ',
        value: 'fois par semaine',
        i18nvalue: t('fois par semaine'),
    },
    4: {
        key: '0.428',
        num: '3 ',
        value: 'fois par semaine',
        i18nvalue: t('fois par semaine'),
    },
    5: {
        key: '0.285',
        num: '',
        value: '2 fois par semaine',
        i18nvalue: t('2 fois par semaine'),
    },
    6: {
        key: '0.142',
        num: '',
        value: '1 fois par semaine',
        i18nvalue: t('1 fois par semaine'),
    },
    7: {
        key: '0.071',
        num: '1 ',
        value: 'fois toutes les 2 semaines',
        i18nvalue: t('fois toutes les 2 semaines'),
    },
    8: {
        key: '0.047',
        num: '1 ',
        value: 'fois toutes les 3 semaines',
        i18nvalue: t('fois toutes les 3 semaines'),
    },
    9: {
        key: '0.034',
        num: '1 ',
        value: 'fois par mois',
        i18nvalue: t('fois par mois'),
    },
    10: {
        key: '0.017',
        num: '1 ',
        value: 'fois tous les 2 mois',
        i18nvalue: t('fois tous les 2 mois'),
    },
    11: {
        key: '0.011',
        num: '1 ',
        value: 'fois tous les 3 mois',
        i18nvalue: t('fois tous les 3 mois'),
    },
    12: {
        key: '0.0055',
        num: '1 ',
        value: 'fois tous les 6 mois',
        i18nvalue: t('fois tous les 6 mois'),
    },
    13: {
        key: '0.0027',
        num: '1 ',
        value: 'fois par an',
        i18nvalue: t('fois par an'),
    },
    14: { key: '0', num: '1 ', value: 'fois', i18nvalue: t('fois') },
}

var numOf = {
    0: {
        i18nlabel: t('Jour J+'),
        label: 'Jour J+',
        key: 'days',
        numof: 100,
        factor: 1,
    },
    1: {
        i18nlabel: t('Semaine W+'),
        label: 'Semaine W+',
        key: 'weeks',
        numof: 12,
        factor: 7,
    },
    2: {
        i18nlabel: t('Mois M+'),
        label: 'Mois M+',
        key: 'months',
        numof: 6,
        factor: 30,
    },
}
var sessionIntervals = {},
    i = 0,
    j = 0,
    len = 0,
    entry = null,
    iterations = null
sessionIntervals[0] = { value: 'Start', key: -1 }
for (i = 0, len = Object.keys(numOf).length; i < len; i++) {
    entry = numOf[i]
    iterations = entry.numof
    for (j = 0; j <= iterations; j++) {
        sessionIntervals[Object.keys(sessionIntervals).length] = {
            value: entry.label + '' + j,
            key: j * entry.factor,
        }
    }
}
export const INTERVALLE = sessionIntervals

numOf = {
    0: {
        i18nlabel: t('Position'),
        label: 'Position',
        key: 'order',
        numof: 100,
        factor: 1,
    },
}
var order100 = {}
for (i = 0, len = Object.keys(numOf).length; i < len; i++) {
    entry = numOf[i]
    iterations = entry.numof
    for (j = 1; j <= iterations; j++) {
        order100[Object.keys(order100).length] = {
            value: entry.label + '' + j,
            key: j * entry.factor,
        }
    }
}
export const ORDER100 = order100

export const PERIODS = {
    0: { key: 'day', value: 'jour(s)' },
    1: { key: 'week', value: 'semaine(s)' },
    2: { key: 'month', value: 'mois' },
}

export const STEP_CATEGORIES = {
    0: { key: 'false', value: 'Vide' },
    1: { key: '0', value: 'Warmup' },
    2: { key: '1', value: 'Step' },
    3: { key: '2', value: 'Recovery' },
}

export const CARDS_DATA_DATES = {
    0: { key: 'P0D', num: '-0 ', value: 'jour', i18nValue: t('jour') },
    1: { key: 'P1D', num: '-1 ', value: 'jour' },
    2: { key: 'P2D', num: '-2 ', value: 'jours', i18nValue: t('jours') },
    3: { key: 'P3D', num: '-3 ', value: 'jours' },
    4: { key: 'P4D', num: '-4 ', value: 'jours' },
    5: { key: 'P5D', num: '-5 ', value: 'jours' },
    6: { key: 'P6D', num: '-6 ', value: 'jours' },
    7: { key: 'P7D', num: '-7 ', value: 'jours' },
    8: { key: 'P8D', num: '-8 ', value: 'jours' },
    9: { key: 'P9D', num: '-9 ', value: 'jours' },
    10: { key: 'P10D', num: '-10 ', value: 'jours' },
}

export const CARDS_VALIDITY = {
    0: {
        key: 'PT50S',
        num: '50 ',
        value: 'secondes',
        i18nValue: t('secondes'),
    },
    1: { key: 'P1D', num: '1 ', value: 'jour' },
    2: { key: 'P2D', num: '2 ', value: 'jours' },
    3: { key: 'P3D', num: '3 ', value: 'jours' },
    4: { key: 'P1W', num: '1 ', value: 'semaine', i18nValue: t('semaine') },
    5: { key: 'P2W', num: '2 ', value: 'semaines', i18nValue: t('semaines') },
    6: { key: 'P3W', num: '3 ', value: 'semaines' },
    7: { key: 'P1M', num: '1 ', value: 'mois', i18nValue: t('mois') },
}

export const CARDS_EMAIL_SENDING_HOURS = {
    0: { num: '7 ', value: 'heure', key: '7', i18nValue: t('heure') },
    1: { num: '8 ', value: 'heure', key: '8' },
    2: { num: '9 ', value: 'heure', key: '9' },
    3: { num: '10 ', value: 'heure', key: '10' },
    4: { num: '11 ', value: 'heure', key: '11' },
    5: { num: '12 ', value: 'heure', key: '12' },
    6: { num: '13 ', value: 'heure', key: '13' },
    7: { num: '14 ', value: 'heure', key: '14' },
    8: { num: '15 ', value: 'heure', key: '15' },
    9: { num: '16 ', value: '1heure', key: '16' },
    10: { num: '17 ', value: 'heure', key: '17' },
    11: { num: '18 ', value: 'heure', key: '18' },
    12: { num: '19 ', value: 'heure', key: '19' },
    13: { num: '20 ', value: 'heure', key: '20' },
    14: { num: '21 ', value: 'heure', key: '21' },
}

export const CARDS_EMAIL_RECAP_DASHBOARD = {
    0: { value: 'Aucun', key: '0' },
    1: { value: 'Semaine', i18nValue: t('Semaine'), key: '1' },
    2: { value: 'Mois', i18nValue: t('Mois'), key: '2' },
    3: { value: 'Année', i18nValue: t('Année'), key: '3' },
}

export const CARDS_ALERT_PUSH_HOURS = {
    0: { num: '7 ', value: 'heure', key: 'PT7H', i18nValue: t('heure') },
    1: { num: '8 ', value: 'heure', key: 'PT8H' },
    2: { num: '9 ', value: 'heure', key: 'PT9H' },
    3: { num: '10 ', value: 'heure', key: 'PT10H' },
    4: { num: '11 ', value: 'heure', key: 'PT11H' },
    5: { num: '12 ', value: 'heure', key: 'PT12H' },
    6: { num: '13 ', value: 'heure', key: 'PT13H' },
    7: { num: '14 ', value: 'heure', key: 'PT14H' },
    8: { num: '15 ', value: 'heure', key: 'PT15H' },
    9: { num: '16 ', value: 'heure', key: 'PT16H' },
    10: { num: '17 ', value: 'heure', key: 'PT17H' },
    11: { num: '18 ', value: 'heure', key: 'PT18H' },
    12: { num: '19 ', value: 'heure', key: 'PT19H' },
    13: { num: '20 ', value: 'heure', key: 'PT20H' },
    14: { num: '21 ', value: 'heure', key: 'PT21H' },
}

export const CARDS_ALERT_PUSH_DATES = {
    0: { value: 'Jour +0', key: 'P0D', i18nValue: t('Jour +0') },
    1: { value: 'Jour +1', key: 'P1D', i18nValue: t('Jour +1') },
    2: { value: 'Jour +2', key: 'P2D', i18nValue: t('Jour +2') },
    3: { value: 'Jour +3', key: 'P3D', i18nValue: t('Jour +3') },
    4: { value: 'Jour +4', key: 'P4D', i18nValue: t('Jour +4') },
    5: { value: 'Jour +5', key: 'P5D', i18nValue: t('Jour +5') },
    6: { value: 'Jour +6', key: 'P6D', i18nValue: t('Jour +6') },
    7: { value: 'Jour +7', key: 'P7D', i18nValue: t('Jour +7') },
}

// const ELEMENT_ARTICLE = 0;
// const ELEMENT_RECIPE = 1;
// const ELEMENT_CARD = 2;
// const ELEMENT_CHALLENGE = 3;
// const ELEMENT_PROGRAM = 4;
// const ELEMENT_COACH = 5;
// const ELEMENT_CONTAINER = 6;
// const ELEMENT_CONVERSATION = 7;
// const ELEMENT_CUSTOMER = 8;
// const ELEMENT_FOOD = 9;
// const ELEMENT_PUSH = 10;
// const ELEMENT_USER = 11;
// const ELEMENT_ALERT = 12;
// const ELEMENT_SESSION = 13;
// const ELEMENT_STEP = 14;
// const ELEMENT_REWARD = 15;
// const ELEMENT_OBJECTIVE = 16;
// const ELEMENT_TOPIC = 17;
// const ELEMENT_ACHIEVEMENT = 18;
// const ELEMENT_TOPIC_TAG = 19;
// const ELEMENT_EMAIL_STORY = 20;
// const ELEMENT_POINT = 21;
// const ELEMENT_LEVEL = 22;
export const adminElements = {
    0: {
        label: 'l‘article',
        i18nLabel: t('l‘article'),
        url: 'private.articles',
    },
    1: {
        label: 'la recette',
        i18nLabel: t('la recette'),
        url: 'private.recipes',
    },
    2: { label: 'la carte', i18nLabel: t('la carte'), url: 'private.cards' },
    3: { label: 'le défi', i18nLabel: t('le défi'), url: 'private.challenges' },
    4: {
        label: 'le programme',
        i18nLabel: t('le programme'),
        url: 'private.programs',
    },
    5: { label: 'le coach', i18nLabel: t('le coach'), url: 'private.coachs' },
    6: {
        label: 'le container',
        i18nLabel: t('le container'),
        url: 'private.containers',
    },
    7: {
        label: 'la conversation',
        i18nLabel: t('la conversation'),
        url: 'private.conversations',
    },
    8: {
        label: 'l‘entreprise',
        i18nLabel: t('l‘entreprise'),
        url: 'private.users.customers',
    },
    9: {
        label: 'l‘aliment',
        i18nLabel: t('l‘aliment'),
        url: 'private.aliments',
    },
    10: { label: 'le push', i18nLabel: t('le push'), url: 'private.alerts' },
    11: {
        label: 'l‘utilisateur',
        i18nLabel: t('l‘utilisateur'),
        url: 'private.users.users',
    },
    12: { label: 'l‘alerte', i18nLabel: t('l‘alerte'), url: 'private.alerts' },
    13: {
        label: 'la session',
        i18nLabel: t('la session'),
        url: 'private.programs.session',
    },
    14: {
        label: 'l‘étape',
        i18nLabel: t('l‘étape'),
        url: 'private.programs.session.steps',
    },
    15: {
        label: 'le reward',
        i18nLabel: t('le reward'),
        url: 'private.reward.actions',
    },
    16: {
        label: 'l‘objectif',
        i18nLabel: t('l‘objectif'),
        url: 'private.objectives',
    },
    17: {
        label: 'le topic',
        i18nLabel: t('le topic'),
        url: 'private.forum.forumfr',
    },
    18: {
        label: 'le badge',
        i18nLabel: t('le badge'),
        url: 'private.gamif.badges',
    },
    19: { label: 'le tag', i18nLabel: t('le tag'), url: 'private.forum.tag' },
    20: { label: 'le mail', i18nLabel: t('le mail'), url: 'private.emails' },
    21: {
        label: 'l‘action',
        i18nLabel: t('l‘action'),
        url: 'private.gamif.actions',
    },
    22: {
        label: 'le niveau',
        i18nLabel: t('le niveau'),
        url: 'private.gamif.levels',
    },
}

export const INSTANCES = {
    1: { value: 'Dev', i18nValue: t('Dev'), key: 'dev' },
    2: { value: 'Release', i18nValue: t('Release'), key: 'release' },
    3: { value: 'Production', i18nValue: t('Production'), key: 'production' },
}

export const APIS = {
    1: {
        value: 'ResiliencePRO',
        i18nValue: t('ResiliencePRO'),
        key: 'oncoflow-api.betterise.me',
        customer: 'oncoflow',
    },
    2: {
        value: 'UniPREMs',
        i18nValue: t('UniPREMs'),
        key: 'unicancer-api.betterise.me',
        customer: 'unicancer',
    },
    3: {
        value: 'UroConnect',
        i18nValue: t('UroConnect'),
        key: 'uroconnect-api.betterise.me',
        customer: 'uroconnect',
    },
}

export const REGISTER_STEPS = {
    1: {
        value: 'Conditions Générales',
        i18nValue: t('Conditions Générales'),
        key: '1',
    },
    2: { value: 'Sécurité', i18nValue: t('Sécurité'), key: '2' },
    3: { value: 'Informations', i18nValue: t('Informations'), key: '3' },
    4: { value: 'Thèmes', i18nValue: t('Thèmes'), key: '4' },
    5: {
        value: 'Objets Connectés',
        i18nValue: t('Objets Connectés'),
        key: '5',
    },
    6: { value: 'Paiement', i18nValue: t('Paiement'), key: '7' },
    7: { value: 'Terminé', i18nValue: t('Terminé'), key: '8' },
}

export const USER_ROLES = {
    0: {
        key: 'ROLE_BETTERISE_SUPERADMIN',
        value: 'Super Administrateur',
        i18nValue: t('Super Administrateur'),
    },
    1: {
        key: 'ROLE_CUSTOMER_SUPER_ADMIN',
        value: 'Customer Stat + Admin + Users',
        i18nValue: t('Customer Stat + Admin + Users'),
    },
    2: {
        key: 'ROLE_CUSTOMER_ADMIN',
        value: 'Customer Stat + Admin',
        i18nValue: t('Customer Stat + Admin'),
    },
    3: {
        key: 'ROLE_CUSTOMER_CONTENT',
        value: 'Customer contenu',
        i18nValue: t('Customer contenu'),
    },
    4: {
        key: 'ROLE_CUSTOMER_STAT',
        value: 'Customer Stat',
        i18nValue: t('Customer Stat'),
    },
    5: { key: 'ROLE_USER', value: 'Utilisateur', i18nValue: t('Utilisateur') },
}

export const LICENSES_STATUS = {
    0: { label: 'B2B creation', key: '0' },
    1: { label: 'B2B renouvellement', key: '1' },
    2: { label: 'B2B suspendue', key: '2' },
    3: { label: 'B2B annulée', key: '3' },
    4: { label: 'B2C creation', key: '10' },
    5: { label: 'B2C renouvellement', key: '11' },
    6: { label: 'B2C suspendue', key: '12' },
    7: { label: 'B2C annulée', key: '13' },
}

export const LANGUAGES = {
    0: { key: 'fr', value: 'fr' },
    1: { key: 'nl', value: 'nl' },
    2: { key: 'en', value: 'en' },
    3: { key: 'es', value: 'es' },
    4: { key: 'it', value: 'it' },
    5: { key: 'de', value: 'de' },
}

export const METRICS_WIDTH = {
    0: { key: '0', value: 'Kilogrammes', i18nValue: t('Kilogrammes') },
    1: { key: '1', value: 'Pounds', i18nValue: t('Pounds') },
    2: { key: '2', value: 'Stones', i18nValue: t('Stones') },
}

export const METRICS_HEIGHT = {
    0: { key: '0', value: 'Centimètres', i18nValue: t('Centimètres') },
    1: { key: '1', value: 'Pieds', i18nValue: t('Pieds') },
}

export const LICENCES_KIND = {
    1: { value: 'Active', i18nValue: t('Active'), key: '0' },
    2: { value: 'Suspendue', i18nValue: t('Suspendue'), key: '1' },
    3: { value: 'Annulée', i18nValue: t('Annulée'), key: '2' },
    4: {
        value: 'Suspendue pour 7 jours',
        i18nValue: t('Suspendue pour 7 jours'),
        key: '3',
    },
    5: { value: 'Pas de licence', i18nValue: t('Pas de licence'), key: '4' },
}

export const BADGES_ACTIONS = {
    0: { value: 'WELCOME', key: 'WELCOME' },
    1: { value: 'AVATAR', key: 'AVATAR' },
    2: { value: 'BRANCHEROBJETCONNECTE', key: 'BRANCHEROBJETCONNECTE' },
    3: { value: 'SECONNECTER', key: 'SECONNECTER' },
    4: { value: 'REGISTRATIONBIRTHDAY', key: 'REGISTRATIONBIRTHDAY' },
    5: { value: 'ACCEPTERDEFI', key: 'ACCEPTERDEFI' },
    6: { value: 'REMPORTERDEFI', key: 'REMPORTERDEFI' },
    7: { value: 'EVAL', key: 'EVAL' },
    8: { value: 'QUIZZOK', key: 'QUIZZOK' },
    9: { value: 'SHAREDEFI', key: 'SHAREDEFI' },
    10: { value: 'ALLDATA', key: 'ALLDATA' },
    11: { value: 'SHARECONSEIL', key: 'SHARECONSEIL' },
    12: { value: 'WALK', key: 'WALK' },
    13: { value: 'CLUMB', key: 'CLUMB' },
    14: { value: 'WALK6000ADAY', key: 'WALK6000ADAY' },
    15: { value: 'HAPPYBIRTHDAY', key: 'HAPPYBIRTHDAY' },
    16: { value: 'LOSSEDEFI', key: 'LOSSEDEFI' },
    17: { value: 'LEVEL', key: 'LEVEL' },
    18: { value: 'PROGRAMSTART', key: 'PROGRAMSTART' },
    19: { value: 'PROGRAMFINISHED', key: 'PROGRAMFINISHED' },
    20: { value: 'PROGRAMSESSIONFINISHED', key: 'PROGRAMSESSIONFINISHED' },
    21: { value: 'SHARECONTENT', key: 'SHARECONTENT' },
    22: { value: 'FINISHEDDEFI', key: 'FINISHEDDEFI' },
    23: { value: 'FINISHEDQUIZZ', key: 'FINISHEDQUIZZ' },
    24: { value: 'PAISCORE', key: 'PAISCORE' },
    25: { value: 'PAISCORE30', key: 'PAISCORE30' },
    26: { value: 'PAISCORE40', key: 'PAISCORE40' },
    27: { value: 'PAISCORE50', key: 'PAISCORE50' },
    28: { value: 'PAISCORE60', key: 'PAISCORE60' },
    29: { value: 'PAISCORE70', key: 'PAISCORE70' },
    30: { value: 'PAISCORE80', key: 'PAISCORE80' },
    31: { value: 'PAISCORE90', key: 'PAISCORE90' },
    32: { value: 'PAISCORE100', key: 'PAISCORE100' },
    33: { value: 'PAICOMPUTED', key: 'PAICOMPUTED' },
    34: { value: 'BUYOFFER', key: 'BUYOFFER' },
    35: { value: 'ADDFAVORITE', key: 'ADDFAVORITE' },
}

export const BADGES_CATEGORIES = {
    1: { value: 'ACTIVITE', i18nValue: t('ACTIVITE'), key: 'ACTIVITE' },
    2: { value: 'ALCOOL', i18nValue: t('ALCOOL'), key: 'ALCOOL' },
    3: { value: 'ANTICANCER', i18nValue: t('ANTICANCER'), key: 'ANTICANCER' },
    4: { value: 'AU TRAVAIL', i18nValue: t('AU TRAVAIL'), key: 'AU TRAVAIL' },
    5: { value: 'BONHEUR', i18nValue: t('BONHEUR'), key: 'BONHEUR' },
    6: {
        value: 'COEUR EN FORME',
        i18nValue: t('COEUR EN FORME'),
        key: 'COEUR EN FORME',
    },
    7: { value: 'DENIVELE', i18nValue: t('DENIVELE'), key: 'DENIVELE' },
    8: { value: 'DISTANCE', i18nValue: t('DISTANCE'), key: 'DISTANCE' },
    9: { value: 'FATIGUE', i18nValue: t('FATIGUE'), key: 'FATIGUE' },
    10: { value: 'MAL DE DOS', i18nValue: t('MAL DE DOS'), key: 'MAL DE DOS' },
    11: { value: 'MORAL', i18nValue: t('MORAL'), key: 'MORAL' },
    12: { value: 'MEMOIRE', i18nValue: t('MEMOIRE'), key: 'MEMOIRE' },
    13: { value: 'NUTRITION', i18nValue: t('NUTRITION'), key: 'NUTRITION' },
    14: { value: 'SERVICE', i18nValue: t('SERVICE'), key: 'SERVICE' },
    15: { value: 'SOMMEIL', i18nValue: t('SOMMEIL'), key: 'SOMMEIL' },
    16: { value: 'STRESS', i18nValue: t('STRESS'), key: 'STRESS' },
    17: { value: 'TABAC', i18nValue: t('TABAC'), key: 'TABAC' },
    18: { value: 'USAGE', i18nValue: t('USAGE'), key: 'USAGE' },
    19: { value: 'LEVEL', i18nValue: t('LEVEL'), key: 'LEVEL' },
}

export const FOOD_DATAS = {
    0: {
        value: 'ORIGGPCD',
        key: 'ORIGGPCD',
        displayed: false,
        unit: '(mg/100g)',
    },
    1: {
        value: 'ORIGGPFR',
        key: 'ORIGGPFR',
        displayed: false,
        unit: '(mg/100g)',
    },
    2: {
        value: 'ORIGFDCD',
        key: 'ORIGFDCD',
        displayed: false,
        unit: '(mg/100g)',
    },
    3: {
        value: 'RIGFDNM',
        key: 'RIGFDNM',
        displayed: false,
        unit: '(mg/100g)',
    },
    4: {
        value: 'Energie1',
        key: 'Energie1',
        displayed: true,
        unit: '(kcal/100g)',
    },
    5: {
        value: 'Energie2',
        key: 'Energie2',
        displayed: true,
        unit: '(kJ/100g)',
    },
    6: {
        value: 'Energie3',
        key: 'Energie3',
        displayed: false,
        unit: '(mg/100g)',
    },
    7: {
        value: 'Energie4',
        key: 'Energie4',
        displayed: false,
        unit: '(mg/100g)',
    },
    8: { value: 'Eau', key: 'Eau', displayed: false, unit: '(mg/100g)' },
    9: {
        value: 'Proteines',
        key: 'Proteines',
        displayed: true,
        unit: '(g/100g)',
    },
    10: {
        value: 'ProteinesBrutes',
        key: 'ProteinesBrutes',
        displayed: false,
        unit: '(mg/100g)',
    },
    11: {
        value: 'Glucides',
        key: 'Glucides',
        displayed: true,
        unit: '(g/100g)',
    },
    12: { value: 'Lipides', key: 'Lipides', displayed: true, unit: '(g/100g)' },
    13: { value: 'Sucres', key: 'Sucres', displayed: true, unit: '(g/100g)' },
    14: { value: 'Amidon', key: 'Amidon', displayed: false, unit: '(mg/100g)' },
    15: {
        value: 'FibresAlimentaires',
        key: 'FibresAlimentaires',
        displayed: true,
        unit: '(g/100g)',
    },
    16: {
        value: 'Polyols',
        key: 'Polyols',
        displayed: false,
        unit: '(mg/100g)',
    },
    17: {
        value: 'Cendres',
        key: 'Cendres',
        displayed: false,
        unit: '(mg/100g)',
    },
    18: { value: 'Alcool', key: 'Alcool', displayed: false, unit: '(mg/100g)' },
    19: {
        value: 'AcidesOrganiques',
        key: 'AcidesOrganiques',
        displayed: false,
        unit: '(mg/100g)',
    },
    20: {
        value: 'AGSatures',
        key: 'AGSatures',
        displayed: true,
        unit: '(g/100g)',
    },
    21: {
        value: 'AGMonoInsatures',
        key: 'AGMonoInsatures',
        displayed: true,
        unit: '(g/100g)',
    },
    22: {
        value: 'AGPolyInsatures',
        key: 'AGPolyInsatures',
        displayed: true,
        unit: '(g/100g)',
    },
    23: { value: 'AG40', key: 'AG40', displayed: false, unit: '(mg/100g)' },
    24: { value: 'AG60', key: 'AG60', displayed: false, unit: '(mg/100g)' },
    25: { value: 'AG80', key: 'AG80', displayed: false, unit: '(mg/100g)' },
    26: { value: 'AG100', key: 'AG100', displayed: false, unit: '(mg/100g)' },
    27: { value: 'AG120', key: 'AG120', displayed: false, unit: '(mg/100g)' },
    28: { value: 'AG140', key: 'AG140', displayed: false, unit: '(mg/100g)' },
    29: { value: 'AG160', key: 'AG160', displayed: false, unit: '(mg/100g)' },
    30: { value: 'AG180', key: 'AG180', displayed: false, unit: '(mg/100g)' },
    31: { value: 'AG181', key: 'AG181', displayed: false, unit: '(mg/100g)' },
    32: { value: 'AG182', key: 'AG182', displayed: false, unit: '(mg/100g)' },
    33: { value: 'AG183', key: 'AG183', displayed: false, unit: '(mg/100g)' },
    34: { value: 'AG204', key: 'AG204', displayed: false, unit: '(mg/100g)' },
    35: { value: 'AG205', key: 'AG205', displayed: false, unit: '(mg/100g)' },
    36: { value: 'AG226', key: 'AG226', displayed: false, unit: '(mg/100g)' },
    37: {
        value: 'Cholesterol',
        key: 'Cholesterol',
        displayed: true,
        unit: '(mg/100g)',
    },
    38: { value: 'Sel', key: 'Sel', displayed: true, unit: '(g/100g)' },
    39: {
        value: 'Calcium',
        key: 'Calcium',
        displayed: true,
        unit: '(mg/100g)',
    },
    40: {
        value: 'Chlorure',
        key: 'Chlorure',
        displayed: false,
        unit: '(mg/100g)',
    },
    41: { value: 'Cuivre', key: 'Cuivre', displayed: true, unit: '(mg/100g)' },
    42: { value: 'Fer', key: 'Fer', displayed: true, unit: '(mg/100g)' },
    43: { value: 'Iode', key: 'Iode', displayed: true, unit: '(µg/100g)' },
    44: {
        value: 'Magnésium',
        key: 'Magnesium',
        displayed: true,
        unit: '(mg/100g)',
    },
    45: {
        value: 'Manganese',
        key: 'Manganese',
        displayed: true,
        unit: '(mg/100g)',
    },
    46: {
        value: 'Phosphore',
        key: 'Phosphore',
        displayed: true,
        unit: '(mg/100g)',
    },
    47: {
        value: 'Potassium',
        key: 'Potassium',
        displayed: true,
        unit: '(mg/100g)',
    },
    48: {
        value: 'Sélénium',
        key: 'Selenium',
        displayed: true,
        unit: '(µg/100g)',
    },
    49: {
        value: 'Sel chlorure de sodium',
        key: 'Sodium',
        displayed: true,
        unit: '(mg/100g)',
    },
    50: { value: 'Zinc', key: 'Zinc', displayed: true, unit: '(mg/100g)' },
    51: {
        value: 'Retinol',
        key: 'Retinol',
        displayed: false,
        unit: '(mg/100g)',
    },
    52: {
        value: 'Beta-Carotène',
        key: 'BetaCarotene',
        displayed: true,
        unit: '(µg/100g)',
    },
    53: {
        value: 'Vitamine D',
        key: 'VitamineD',
        displayed: true,
        unit: '(µg/100g)',
    },
    54: {
        value: 'Vitamine E',
        key: 'VitamineE',
        displayed: true,
        unit: '(mg/100g)',
    },
    55: {
        value: 'Vitamine K1',
        key: 'VitamineK1',
        displayed: true,
        unit: '(µg/100g)',
    },
    56: {
        value: 'Vitamine K2',
        key: 'VitamineK2',
        displayed: true,
        unit: '(µg/100g)',
    },
    57: {
        value: 'Vitamine C',
        key: 'VitamineC',
        displayed: true,
        unit: '(mg/100g)',
    },
    58: {
        value: 'Vitamine B1 ou Thiamine',
        key: 'VitamineB1',
        displayed: true,
        unit: '(mg/100g)',
    },
    59: {
        value: 'Vitamine B2 ou Riboflavine',
        key: 'VitamineB2',
        displayed: true,
        unit: '(mg/100g)',
    },
    60: {
        value: 'Vitamine B3 ou PP ou Niacine',
        key: 'VitamineB3',
        displayed: true,
        unit: '(mg/100g)',
    },
    61: {
        value: 'Vitamine B5 ou Acide pantothénique',
        key: 'VitamineB5',
        displayed: true,
        unit: '(mg/100g)',
    },
    62: {
        value: 'Vitamine B6',
        key: 'VitamineB6',
        displayed: true,
        unit: '(mg/100g)',
    },
    63: {
        value: 'Vitamine B9 ou Folates totaux',
        key: 'VitamineB9',
        displayed: true,
        unit: '(µg/100g)',
    },
    64: {
        value: 'Vitamine B12',
        key: 'VitamineB12',
        displayed: true,
        unit: '(µg/100g)',
    },
}

import themeFood from '@app/img/ico/corpo/picto-corpo_food.png'
import themeFoodx2 from '@app/img/ico/corpo/picto-corpo_food@2x.png'
import themeMove from '@app/img/ico/corpo/picto-corpo_move.png'
import themeMovex2 from '@app/img/ico/corpo/picto-corpo_move@2x.png'
import themePersonal from '@app/img/ico/corpo/picto-corpo_personal.png'
import themePersonalx2 from '@app/img/ico/corpo/picto-corpo_personal@2x.png'
import themeSleep from '@app/img/ico/corpo/picto-corpo_sleep.png'
import themeSleepx2 from '@app/img/ico/corpo/picto-corpo_sleep@2x.png'
import themeWellness from '@app/img/ico/corpo/picto-corpo_wellness.png'
import themeWellnessx2 from '@app/img/ico/corpo/picto-corpo_wellness@2x.png'
import themeZen from '@app/img/ico/corpo/picto-corpo_zen.png'
import themeZenx2 from '@app/img/ico/corpo/picto-corpo_zen@2x.png'

export const THEMES_CONTENT = [
    {
        key: 22,
        value: 'Je bouge',
        i18nValue: t('Je bouge'),
        type: 'corpo_move',
        img: {
            normal: themeMove,
            retina: themeMovex2,
        },
    },
    {
        key: 23,
        value: 'Je suis zen',
        i18nValue: t('Je suis zen'),
        type: 'corpo_zen',
        img: {
            normal: themeZen,
            retina: themeZenx2,
        },
    },
    {
        key: 24,
        value: 'Je mange équilibré',
        i18nValue: t('Je mange équilibré'),
        type: 'corpo_food',
        img: {
            normal: themeFood,
            retina: themeFoodx2,
        },
    },
    {
        key: 25,
        value: 'Je concilie ma vie pro/perso',
        i18nValue: t('Je concilie ma vie pro/perso'),
        type: 'corpo_personal',
        img: {
            normal: themePersonal,
            retina: themePersonalx2,
        },
    },
    {
        key: 26,
        value: 'Je gère mon sommeil',
        i18nValue: t('Je gère mon sommeil'),
        type: 'corpo_sleep',
        img: {
            normal: themeSleep,
            retina: themeSleepx2,
        },
    },
    {
        key: 27,
        value: 'Je suis bien dans mon environnement de travail',
        i18nValue: t('Je suis bien dans mon environnement de travail'),
        type: 'corpo_wellness',
        img: {
            normal: themeWellness,
            retina: themeWellnessx2,
        },
    },
]

import themeFood2 from '@app/img/themes/picto-nutrition.png'
import themeActivity from '@app/img/themes/picto-activite.png'
import themeSleep2 from '@app/img/themes/picto-sommeil.png'
import themeStress from '@app/img/themes/picto-stress.png'
import themeShape from '@app/img/themes/picto-moral.png'
import themeTobacco from '@app/img/themes/picto-tabac.png'
import themeAlcool from '@app/img/themes/picto-alcool.png'
import themeMemory from '@app/img/themes/picto-memoire.png'
import themeAnticancer from '@app/img/themes/picto-anticancer.png'
import themeBackache from '@app/img/themes/picto-maldedos.png'
import themeWorkingPosition from '@app/img/themes/picto-autravail.png'
import themeShapedHeart from '@app/img/themes/picto-coeur.png'
import themeHappinessPsychology from '@app/img/themes/picto-bonheur.png'
import themeProfil from '@app/img/themes/picto-profil.png'
import themeFollowedWeight from '@app/img/themes/picto-poids.png'
import themeHappyPregnancy from '@app/img/themes/picto-grossesse.png'
import themeTiredness from '@app/img/themes/picto-fatigue.png'
import themeSport from '@app/img/themes/picto-sport.png'
import themeDenivele from '@app/img/themes/picto-activite.png'
import themeWaistsize from '@app/img/themes/picto-tourdetaille.png'

export const CARDS_TYPES_USER_IGNORE = [19, 20]
export const CARDS_TYPES = {
    0: { theme: '', value: '', key: 0, color: '' },
    1: {
        theme: 'food',
        value: 'Nutrition',
        i18nLabel: t('Nutrition'),
        key: 1,
        color: 'FF6F41',
        order: 13,
        img: themeFood2,
        display: 'both',
    },
    2: {
        theme: 'activity',
        value: 'Activité',
        i18nLabel: t('Activité'),
        key: 2,
        color: '049588',
        order: 1,
        img: themeActivity,
        display: 'both',
    },
    3: {
        theme: 'sleep',
        value: 'Sommeil',
        i18nLabel: t('Sommeil'),
        key: 3,
        color: '3E51B5',
        order: 16,
        img: themeSleep2,
        display: 'both',
    },
    4: {
        theme: 'stress',
        value: 'Stress',
        i18nLabel: t('Stress'),
        key: 4,
        color: 'E81E63',
        order: 17,
        img: themeStress,
        display: 'both',
    },
    5: {
        theme: 'shape',
        value: 'Moral',
        i18nLabel: t('Moral'),
        key: 5,
        color: 'FEC009',
        order: 12,
        img: themeShape,
        display: 'both',
    },
    6: {
        theme: 'tobacco',
        value: 'Tabac',
        i18nLabel: t('Tabac'),
        key: 6,
        color: 'FF9A00',
        order: 19,
        img: themeTobacco,
        display: 'both',
    },
    7: {
        theme: 'alcool',
        value: 'Alcool',
        i18nLabel: t('Alcool'),
        key: 8,
        color: 'A62671',
        order: 2,
        img: themeAlcool,
        display: 'both',
    },
    8: {
        theme: 'memory',
        value: 'Mémoire',
        i18nLabel: t('Mémoire'),
        key: 9,
        color: '00BABA',
        order: 12,
        img: themeMemory,
        display: 'both',
    },
    9: {
        theme: 'anticancer',
        value: 'Anti-cancer',
        i18nLabel: t('Anti-cancer'),
        key: 10,
        color: '',
        order: 3,
        img: themeAnticancer,
        display: 'both',
    },
    10: {
        theme: 'backache',
        value: 'Mal de dos',
        i18nLabel: t('Mal de dos'),
        key: 11,
        color: 'C83B4C',
        order: 11,
        img: themeBackache,
        display: 'both',
    },
    11: {
        theme: 'working-position',
        value: 'Au travail',
        i18nLabel: t('Au travail'),
        key: 12,
        color: '4EC9DF',
        order: 4,
        img: themeWorkingPosition,
        display: 'both',
    },
    12: {
        theme: 'shaped-heart',
        value: 'Coeur',
        i18nLabel: t('Coeur'),
        key: 13,
        color: '',
        order: 6,
        img: themeShapedHeart,
        display: 'both',
    },
    13: {
        theme: 'happiness-psychology',
        value: 'Bonheur',
        i18nLabel: t('Bonheur'),
        key: 14,
        color: '',
        order: 5,
        img: themeHappinessPsychology,
        display: 'both',
    },
    14: {
        theme: 'profil',
        value: 'Profil',
        i18nLabel: t('Profil'),
        key: 15,
        color: '',
        order: 14,
        img: themeProfil,
        display: 'both',
    },
    15: {
        theme: 'followed-weight',
        value: 'Suivis de poids',
        i18nLabel: t('Suivis de poids'),
        key: 16,
        color: '5F7D8B',
        order: 15,
        img: themeFollowedWeight,
        display: 'both',
    },
    16: {
        theme: 'happy-pregnancy',
        value: 'Grossesse',
        i18nLabel: t('Grossesse'),
        key: 17,
        color: '7481CF',
        order: 10,
        img: themeHappyPregnancy,
        display: 'both',
    },
    17: {
        theme: 'tiredness',
        value: 'Fatigue',
        i18nLabel: t('Fatigue'),
        key: 18,
        color: '3576A1',
        order: 9,
        img: themeTiredness,
        display: 'both',
    },
    18: {
        theme: 'sport',
        value: 'Sport',
        i18nLabel: t('Sport'),
        key: 19,
        color: '4CAF50',
        order: 18,
        img: themeSport,
        display: 'both',
    },
    19: {
        theme: 'denivele',
        value: 'Dénivelé',
        i18nLabel: t('Dénivelé'),
        key: 20,
        color: '049588',
        order: 8,
        img: themeDenivele,
        display: 'both',
    },
    20: {
        theme: 'je_bouge',
        value: 'Je bouge',
        i18nLabel: t('Je bouge'),
        key: 22,
        color: '049588',
        order: 22,
        img: themeActivity,
        display: 'card',
    },
    21: {
        theme: 'je_suis_zen',
        value: 'Je suis zen',
        i18nLabel: t('Je suis zen'),
        key: 23,
        color: 'E81E63',
        order: 23,
        img: themeStress,
        display: 'card',
    },
    22: {
        theme: 'je_mange_equilibre',
        value: 'Je mange équilibré',
        i18nLabel: t('Je mange équilibré'),
        key: 24,
        color: 'FF6F41',
        order: 24,
        img: themeFood2,
        display: 'card',
    },
    23: {
        theme: 'je_concilie_ma_vie_pro_perso',
        value: 'Je concilie ma vie pro/perso',
        i18nLabel: t('Je concilie ma vie pro/perso'),
        key: 25,
        color: '4CAF50',
        order: 25,
        img: themeSport,
        display: 'card',
    },
    24: {
        theme: 'je_gere_mon_sommeil',
        value: 'Je gère mon sommeil',
        i18nLabel: t('Je gère mon sommeil'),
        key: 26,
        color: '3E51B5',
        order: 26,
        img: themeSleep2,
        display: 'card',
    },
    25: {
        theme: 'je_suis_bien_dans_mon_environnement_de_travail',
        value: 'Je suis bien dans mon environnement de travail',
        i18nLabel: t('Je suis bien dans mon environnement de travail'),
        key: 27,
        color: 'FEC009',
        order: 27,
        img: themeShape,
        display: 'card',
    },
    26: {
        theme: 'waistsize',
        value: 'Tour de taille',
        i18nLabel: t('Tour de taille'),
        key: 30,
        color: '76D3CA',
        order: 28,
        img: themeWaistsize,
        display: 'both',
    },
}

export const CARDS_MEDICAL_STUDIES = {
    1: { value: 'Palatine', i18nLabel: t('Palatine'), key: 'palatine' },
    2: { value: 'Curve', i18nLabel: t('Curve'), key: 'curve' },
    3: { value: 'ToutK00001', i18nLabel: t('ToutK00001'), key: 'toutk00001' },
    4: {
        value: 'ToutK00002IIA',
        i18nLabel: t('ToutK00002IIA'),
        key: 'toutk00002',
    },
    5: { value: 'ToutK00003', i18nLabel: t('ToutK00003'), key: 'toutk00003' },
    6: { value: 'ToutK00003', i18nLabel: t('ToutK00003'), key: 'toutk00003' },
    7: { value: 'ToutK00004', i18nLabel: t('ToutK00004'), key: 'toutk00004' },
    8: { value: 'ToutK00005', i18nLabel: t('ToutK00005'), key: 'toutk00005' },
    9: { value: 'KidneyPRO', i18nLabel: t('KidneyPRO'), key: 'kidneypro' },
    10: { value: 'cardioIC', i18nLabel: t('cardioIC'), key: 'cardioic' },
    11: {
        value: 'Accurate 99',
        i18nLabel: t('Accurate 99'),
        key: 'uroaccurate99',
    },
    12: { value: 'Rein', i18nLabel: t('Rein'), key: 'urorein' },
    13: { value: 'Rein-Ambu', i18nLabel: t('Rein-Ambu'), key: 'uroreinambu' },
    15: { value: 'Prostate', i18nLabel: t('Prostate'), key: 'uroprostate' },
    14: { value: 'Vessie', i18nLabel: t('Vessie'), key: 'urovessie' },
    16: {
        value: 'Parcours Socratic 152 - Surveillance Active',
        i18nLabel: t('Parcours Socratic 152 - Surveillance Active'),
        key: 'urosocratic152sa',
    },
    17: {
        value: 'Parcours Socratic 152 - Chirurgie',
        i18nLabel: t('Parcours Socratic 152 - Chirurgie'),
        key: 'urosocratic152sur',
    },
    18: {
        value: 'Colon/Rectum - CITC',
        i18nLabel: t('Colon/Rectum - CITC'),
        key: 'colonrectumcitc',
    },
    19: {
        value: 'Colon/Rectum - R',
        i18nLabel: t('Colon/Rectum - R'),
        key: 'colonrectumr',
    },
    20: {
        value: 'Colon/Rectum - SPCITC',
        i18nLabel: t('Colon/Rectum - SPCITC'),
        key: 'colonrectumspcitc',
    },
    21: {
        value: 'Colon/Rectum - SPRCC',
        i18nLabel: t('Colon/Rectum - SPRCC'),
        key: 'colonrectumsprcc',
    },
    22: {
        value: 'Colon/Rectum - Adjuvant',
        i18nLabel: t('Colon/Rectum - Adjuvant'),
        key: 'colonrectumadjuvant',
    },
    23: {
        value: 'Poumon - CITC',
        i18nLabel: t('Poumon - CITC'),
        key: 'lungcitc',
    },
    24: { value: 'Poumon - RC', i18nLabel: t('Poumon - R'), key: 'lungrc' },
    25: {
        value: 'Poumon - SPCITC',
        i18nLabel: t('Poumon - SPCITC'),
        key: 'lungspcitc',
    },
    26: {
        value: 'Poumon - SPRCC',
        i18nLabel: t('Poumon - SPRCC'),
        key: 'lungsprcc',
    },
    27: {
        value: 'Poumon - Adjuvant',
        i18nLabel: t('Poumon - Adjuvant'),
        key: 'lungadjuvant',
    },
    28: {
        value: 'Prostate - CITC',
        i18nLabel: t('Prostate - CITC'),
        key: 'prostatecitc',
    },
    29: {
        value: 'Prostate - R',
        i18nLabel: t('Prostate - R'),
        key: 'prostater',
    },
    30: {
        value: 'Prostate - SPCITC',
        i18nLabel: t('Prostate - SPCITC'),
        key: 'prostatespcitc',
    },
    31: {
        value: 'Prostate - SPRCC',
        i18nLabel: t('Prostate - SPRCC'),
        key: 'prostatesprcc',
    },
    32: {
        value: 'Prostate - Adjuvant',
        i18nLabel: t('Prostate - Adjuvant'),
        key: 'prostateadjuvant',
    },
    33: {
        value: 'Radiothérapie Stéréotaxique Métastase Cérébrale',
        i18nLabel: t('Radiothérapie Stéréotaxique Métastase Cérébrale'),
        key: 'radiotherapymetbrain',
    },
    34: {
        value: 'Rein - CITC',
        i18nLabel: t('Rein - CITC'),
        key: 'kidneycitc',
    },
    35: { value: 'Rein - SPC', i18nLabel: t('Rein - SPC'), key: 'kidneyspc' },
    36: {
        value: 'Rein - SPCITC',
        i18nLabel: t('Rein - SPCITC'),
        key: 'kidneyspcitc',
    },
    37: {
        value: 'Rein - Adjuvant',
        i18nLabel: t('Rein - Adjuvant'),
        key: 'kidneyadjuvant',
    },
    38: {
        value: 'Sein - CITC',
        i18nLabel: t('Sein - CITC'),
        key: 'breastcitc',
    },
    39: { value: 'Sein - R', i18nLabel: t('Sein - R'), key: 'breastr' },
    40: {
        value: 'Sein - SPCITC',
        i18nLabel: t('Sein - SPCITC'),
        key: 'breastspcitc',
    },
    41: {
        value: 'Sein - SPRCC',
        i18nLabel: t('Sein - SPRCC'),
        key: 'breastsprcc',
    },
    42: {
        value: 'Sein - Adjuvant',
        i18nLabel: t('Sein - Adjuvant'),
        key: 'breastadjuvant',
    },
    43: {
        value: 'VADS/Oesophage - CITC',
        i18nLabel: t('VADS/Oesophage - CITC'),
        key: 'uatesophaguscitc',
    },
    44: {
        value: 'VADS/Oesophage - R',
        i18nLabel: t('VADS/Oesophage - R'),
        key: 'uatesophagusr',
    },
    45: {
        value: 'VADS/Oesophage - SPCITC',
        i18nLabel: t('VADS/Oesophage - SPCITC'),
        key: 'uatesophagusspcitc',
    },
    46: {
        value: 'VADS/Oesophage - SPRCC',
        i18nLabel: t('VADS/Oesophage - SPRCC'),
        key: 'uatesophagussprcc',
    },
    47: {
        value: 'VADS/Oesophage - Adjuvant',
        i18nLabel: t('VADS/Oesophage - Adjuvant'),
        key: 'uatesophagusadjuvant',
    },
    48: {
        value: 'Foie/VB/Pancréas/Estomac - CITC',
        i18nLabel: t('Foie/VB/Pancréas/Estomac - CITC'),
        key: 'livergallbladerpancreasstomachcitc',
    },
    49: {
        value: 'Foie/VB/Pancréas/Estomac - R',
        i18nLabel: t('Foie/VB/Pancréas/Estomac - R'),
        key: 'livergallbladerpancreasstomachrc',
    },
    50: {
        value: 'Foie/VB/Pancréas/Estomac - SPCITC',
        i18nLabel: t('Foie/VB/Pancréas/Estomac - SPCITC'),
        key: 'livergallbladerpancreasstomachspcitc',
    },
    51: {
        value: 'Foie/VB/Pancréas/Estomac - SPRC',
        i18nLabel: t('Foie/VB/Pancréas/Estomac - SPRC'),
        key: 'livergallbladerpancreasstomachsprcc',
    },
    52: {
        value: 'Foie/VB/Pancréas/Estomac - Adjuvant',
        i18nLabel: t('Foie/VB/Pancréas/Estomac - Adjuvant'),
        key: 'livergallbladerpancreasstomachadjuvant',
    },
    53: {
        value: 'Vessie - CITC',
        i18nLabel: t('Vessie - CITC'),
        key: 'bladercitc',
    },
    54: { value: 'Vessie - RC', i18nLabel: t('Vessie - RC'), key: 'bladerrc' },
    55: {
        value: 'Vessie - SPCITC',
        i18nLabel: t('Vessie - SPCITC'),
        key: 'bladerspcitc',
    },
    56: {
        value: 'Vessie - SPRC',
        i18nLabel: t('Vessie - SPRC'),
        key: 'bladersprc',
    },
    57: {
        value: 'Vessie - Adjuvant',
        i18nLabel: t('Vessie - Adjuvant'),
        key: 'bladeradjuvant',
    },
    58: {
        value: 'Gynécologique - CITC',
        i18nLabel: t('Gynécologique - CITC'),
        key: 'gynecologicalcitc',
    },
    59: {
        value: 'Gynécologique - R',
        i18nLabel: t('Gynécologique - R'),
        key: 'gynecologicalrc',
    },
    60: {
        value: 'Gynécologique - SPCITC',
        i18nLabel: t('Gynécologique - SPCITC'),
        key: 'gynecologicalspcitc',
    },
    61: {
        value: 'Gynécologique - SPRCC',
        i18nLabel: t('Gynécologique - SPRCC'),
        key: 'gynecologicalsprcc',
    },
    62: {
        value: 'Gynécologique - Adjuvant',
        i18nLabel: t('Gynécologique - Adjuvant'),
        key: 'gynecologicaladjuvant',
    },
    63: { value: 'OK Cure', i18nLabel: t('OK Cure'), key: 'okcure' },
    64: {
        value: 'Ovaire - CITC',
        i18nLabel: t('Ovaire - CITC'),
        key: 'ovarycitc',
    },
    65: {
        value: 'Ovaire - SPC',
        i18nLabel: t('Ovaire - SPC'),
        key: 'ovaryspc',
    },
    66: {
        value: 'Ovaire - SPCITC',
        i18nLabel: t('Ovaire - SPCITC'),
        key: 'ovaryspcitc',
    },
    67: {
        value: 'Ovaire - Adjuvant',
        i18nLabel: t('Ovaire - Adjuvant'),
        key: 'ovaryadjuvant',
    },
    68: {
        value: 'Onco-Dermato - Adjuvant',
        i18nLabel: t('Onco-Dermato - Adjuvant'),
        key: 'oncodermatoadjuvant',
    },
    69: {
        value: 'Onco-Dermato - CITC',
        i18nLabel: t('Onco-Dermato - CITC'),
        key: 'oncodermatocitc',
    },
    70: {
        value: 'Onco-Dermato - SPC',
        i18nLabel: t('Onco-Dermato - SPC'),
        key: 'oncodermatospc',
    },
    71: {
        value: 'Onco-Dermato - SPCITC',
        i18nLabel: t('Onco-Dermato - SPCITC'),
        key: 'oncodermatospcitc',
    },
    72: {
        value: 'Onco-Hémato - CITC',
        i18nLabel: t('Onco-Hémato - CITC'),
        key: 'oncohematocitc',
    },
    73: {
        value: 'Onco-Hémato - SPT',
        i18nLabel: t('Onco-Hémato - SPT'),
        key: 'oncohematospt',
    },
    74: {
        value: 'Onco-Hémato - Post Greffe',
        i18nLabel: t('Onco-Hémato - Post Greffe'),
        key: 'oncohematoposttransplant',
    },
    75: {
        value: 'Onco-Hémato - Adjuvant',
        i18nLabel: t('Onco-Hémato - Adjuvant'),
        key: 'oncohematoadjuvant',
    },
    76: { value: 'OPERA', i18nLabel: t('OPERA'), key: 'opera' },
    77: { value: 'Less', i18nLabel: t('Less'), key: 'less' },
    78: {
        value: 'Post Chirurgie',
        i18nLabel: t('Post Chirurgie'),
        key: 'postsurgery',
    },
    79: {
        value: 'Capécitabine monothérapie - Tout Cancer',
        i18nLabel: t('Capécitabine monothérapie - Tout Cancer'),
        key: 'capecitabinemonotherapy',
    },
    81: {
        value: 'Radiochimiothérapie Capécitabine - Rectum',
        i18nLabel: t('Radiochimiothérapie Capécitabine - Rectum'),
        key: 'chemoradiotherapycapecitabine',
    },
    80: {
        value: 'Nivolumab / Ipilimumab - Tout Cancer',
        i18nLabel: t('Nivolumab / Ipilimumab - Tout Cancer'),
        key: 'nivolumabipilimumab',
    },
    82: {
        value: 'Tête & Cou - Radiochimiothérapie Cisplatine / Carboplatine',
        i18nLabel: t(
            'Tête & Cou - Radiochimiothérapie Cisplatine / Carboplatine'
        ),
        key: 'rcctc',
    },
    83: {
        value: 'Chimiothérapie FOLFIRINOX - Pancréas',
        i18nLabel: t('Chimiothérapie FOLFIRINOX - Pancréas'),
        key: 'cfp',
    },
    84: {
        value: 'Témozolomide +/- Radiothérapie - Neuro onco',
        i18nLabel: t('Témozolomide +/- Radiothérapie - Neuro onco'),
        key: 'notr',
    },
    85: {
        value: 'Epirubicine + Cyclophosphamide EC - Sein',
        i18nLabel: t('Epirubicine + Cyclophosphamide EC - Sein'),
        key: 'epicyclobreast',
    },
    86: {
        value: 'Paclitaxel + Trastuzumab - Sein',
        i18nLabel: t('Paclitaxel + Trastuzumab - Sein'),
        key: 'paclitrastubreast',
    },
    87: {
        value: 'Palbociclib + Letrozole - Sein ',
        i18nLabel: t('Palbociclib + Letrozole - Sein '),
        key: 'anticdkfoursixhormono',
    },
    88: {
        value: 'Radiochimiothérapie Carboplatine + Paclitaxel - Oesophage',
        i18nLabel: t(
            'Radiochimiothérapie Carboplatine + Paclitaxel - Oesophage'
        ),
        key: 'rctcarbopacliesophagus',
    },
    89: {
        value: 'Trastuzumab monothérapie - Tout cancer',
        i18nLabel: t('Trastuzumab monothérapie - Tout cancer'),
        key: 'trastumonotherapy',
    },
    90: {
        value: 'Cabozantinib + Nivolumab - tout cancer',
        i18nLabel: t('Cabozantinib + Nivolumab - tout cancer'),
        key: 'tkimmuno',
    },

    91: {
        value: 'Déterminants de santé',
        i18nLabel: t('Déterminants de santé'),
        key: 'doh',
    },
    92: {
        value: 'Axitinib + Pembrolizumab - Tout cancer',
        i18nLabel: t('Axitinib + Pembrolizumab - Tout cancer'),
        key: 'axipembro',
    },
    93: {
        value: 'Docetaxel monothérapie - Tout cancer',
        i18nLabel: t('Docetaxel monothérapie - Tout cancer'),
        key: 'docetaxel',
    },
    94: {
        value: 'Lenvatinib + Pembrolizumab - Tout cancer',
        i18nLabel: t('Lenvatinib + Pembrolizumab - Tout cancer'),
        key: 'lenvapembro',
    },
    95: {
        value: 'Pembrolizumab monothérapie - Tout cancer',
        i18nLabel: t('Pembrolizumab monothérapie - Tout cancer'),
        key: 'pembro',
    },
    96: {
        value: 'DiPRU',
        i18nLabel: t('DiPRU'),
        key: 'urodipru',
    },
    97: {
        value: 'Anxiety 113',
        i18nLabel: t('Anxiety 113'),
        key: 'uroanxiety113',
    },
    98: {
        value: 'Anxiety 113 + DiPRU',
        i18nLabel: t('Anxiety 113 + DiPRU'),
        key: 'uroanxiety113dipru',
    },
    99: {
        value: 'Axitinib + Avelumab - Tout cancer',
        i18nLabel: t('Axitinib + Avelumab - Tout cancer'),
        key: 'axitiavelu',
    },
    100: {
        value: 'Folfiri + Bevacizumab - Tout Cancer',
        i18nLabel: t('Folfiri + Bevacizumab - Tout Cancer'),
        key: 'folfiribeva',
    },
    101: {
        value: 'Folfirinox - Tout cancer',
        i18nLabel: t('Folfirinox - Tout cancer'),
        key: 'folfirinox',
    },
    102: {
        value: 'Folfox - Tout cancer',
        i18nLabel: t('Folfox - Tout cancer'),
        key: 'folfox',
    },
    103: {
        value: 'PSMA - Prostate',
        i18nLabel: t('PSMA - Prostate'),
        key: 'psmaprostate',
    },
    104: {
        value: 'Socle commun & OK Cure - Tout cancer',
        i18nLabel: t('Socle commun & OK Cure - Tout cancer'),
        key: 'toutk5okcure',
    },
    105: {
        value: 'Socle commun & OK Cure HAD - Tout cancer',
        i18nLabel: t('Socle commun & OK Cure HAD - Tout cancer'),
        key: 'okcurehadfss',
    },
    106: {
        value: 'Acetate d’abiraterone + HT conventionnelle - Prostate',
        i18nLabel: t('Acetate d’abiraterone + HT conventionnelle - Prostate'),
        key: 'abirateroneht',
    },
    107: {
        value: 'Carboplatine + Paclitaxel + Pembrolizumab - Tout Cancer',
        i18nLabel: t('Carboplatine + Paclitaxel + Pembrolizumab - Tout Cancer'),
        key: 'cabopaclipembro',
    },
    108: {
        value: 'CARE 1',
        i18nLabel: t('CARE 1'),
        key: 'care1',
    },
    109: {
        value: 'ANASTROZOLE ou EXEMESTANE ou FULVESTRANT ou LETROZOLE ou TAMOXIFEN',
        i18nLabel: t(
            'ANASTROZOLE ou EXEMESTANE ou FULVESTRANT ou LETROZOLE ou TAMOXIFEN'
        ),
        key: 'anaemefulveletrotamo',
    },
    110: {
        value: 'PRRT - Tout Cancer',
        i18nLabel: t('PRRT - Tout Cancer'),
        key: 'prrt',
    },
    111: {
        value: 'Radio-hormonothérapie Prostate + Pelvis',
        i18nLabel: t('Radio-hormonothérapie Prostate + Pelvis'),
        key: 'radiohormonoprostpelvis',
    },
    112: {
        value: 'Personalize 114',
        i18nLabel: t('Personalize 114'),
        key: 'uropersonalize114',
    },
    113: {
        value: 'Therapie orale - Tout cancer',
        i18nLabel: t('Therapie orale - Tout cancer'),
        key: 'toaphm',
    },
    114: {
        value: 'Hématologie',
        i18nLabel: t('Hématologie'),
        key: 'hemato',
    },
    115: {
        value: 'TUB-030 - All other patients',
        i18nLabel: t('TUB-030 - All other patients'),
        key: 'tub030allother',
    },
    116: {
        value: 'TUB-030 - Head and Neck Squamous Cell Carcinomas (HNSCC)',
        i18nLabel: t(
            'TUB-030 - Head and Neck Squamous Cell Carcinomas (HNSCC)'
        ),
        key: 'tub030hnscc',
    },
    117: {
        value: 'TUB-030 - Non-Small-Cell Lung Cancer (NSCLC)',
        i18nLabel: t('TUB-030 - Non-Small-Cell Lung Cancer (NSCLC)'),
        key: 'tub030nsclc',
    },
    118: {
        value: 'Questionnaire adapaté',
        i18nLabel: t('Questionnaire adapaté'),
        key: 'tailored',
    },
    119: {
        value: 'EQ5D5L',
        i18nLabel: t('EQ5D5L'),
        key: 'eq5d5l',
    },
    120: {
        value: 'QLQC30',
        i18nLabel: t('QLQC30'),
        key: 'qlqc30',
    },
}

export const CARDS_MEDICAL_MOMENT = {
    curve: {
        0: { value: 'Moment #1', i18nLabel: t('Moment #1'), key: 'M1' },
        1: { value: 'Moment #2', i18nLabel: t('Moment #2'), key: 'M2' },
        2: { value: 'Moment #3', i18nLabel: t('Moment #3'), key: 'M3' },
    },
    less: {
        0: { value: 'Moment #1', i18nLabel: t('Moment #1'), key: 'M1' },
        1: { value: 'Moment #2', i18nLabel: t('Moment #2'), key: 'M2' },
        2: { value: 'Moment #3', i18nLabel: t('Moment #3'), key: 'M3' },
        3: { value: 'Moment #4', i18nLabel: t('Moment #4'), key: 'M4' },
        4: { value: 'Moment #5', i18nLabel: t('Moment #5'), key: 'M5' },
    },
    urorein: {
        0: { value: 'J-1', i18nLabel: t('J-1'), key: 'J-1' },
        1: { value: 'J+2', i18nLabel: t('J+2'), key: 'J+2' },
        2: { value: 'J+7', i18nLabel: t('J+7'), key: 'J+7' },
        3: { value: 'J+14', i18nLabel: t('J+14'), key: 'J+14' },
        4: { value: 'J+21', i18nLabel: t('J+21'), key: 'J+21' },
        5: { value: 'J+30', i18nLabel: t('J+30'), key: 'J+30' },
    },
    uroreinambu: {
        0: { value: 'J-1', i18nLabel: t('J-1'), key: 'J-1' },
        1: { value: 'J+1', i18nLabel: t('J+1'), key: 'J+1' },
        2: { value: 'J+2', i18nLabel: t('J+2'), key: 'J+2' },
        3: { value: 'J+7', i18nLabel: t('J+7'), key: 'J+7' },
        4: { value: 'J+14', i18nLabel: t('J+14'), key: 'J+14' },
        5: { value: 'J+21', i18nLabel: t('J+21'), key: 'J+21' },
        6: { value: 'J+30', i18nLabel: t('J+30'), key: 'J+30' },
    },
    uroprostate: {
        0: { value: 'J-1', i18nLabel: t('J-1'), key: 'J-1' },
        1: { value: 'J+2', i18nLabel: t('J+2'), key: 'J+2' },
        2: { value: 'J+6', i18nLabel: t('J+6'), key: 'J+6' },
        3: { value: 'J+9', i18nLabel: t('J+9'), key: 'J+9' },
        4: { value: 'J+14', i18nLabel: t('J+14'), key: 'J+14' },
        5: { value: 'J+21', i18nLabel: t('J+21'), key: 'J+21' },
        6: { value: 'J+30', i18nLabel: t('J+30'), key: 'J+30' },
    },
    urovessie: {
        0: { value: 'J-1', i18nLabel: t('J-1'), key: 'J-1' },
        1: { value: 'J+3', i18nLabel: t('J+3'), key: 'J+3' },
        2: { value: 'J+7', i18nLabel: t('J+7'), key: 'J+7' },
        3: { value: 'J+14', i18nLabel: t('J+14'), key: 'J+14' },
        4: { value: 'J+21', i18nLabel: t('J+21'), key: 'J+21' },
        5: { value: 'J+30', i18nLabel: t('J+30'), key: 'J+30' },
    },
    uroaccurate99: {
        0: { value: 'Moment #0', i18nLabel: t('Moment #0'), key: 'M0' },
        1: { value: 'Moment #1', i18nLabel: t('Moment #1'), key: 'M1' },
        2: { value: 'Moment #3', i18nLabel: t('Moment #3'), key: 'M3' },
        3: { value: 'Moment #6', i18nLabel: t('Moment #6'), key: 'M6' },
        4: { value: 'Moment #12', i18nLabel: t('Moment #12'), key: 'M12' },
    },
    uroanxiety113: {
        0: { value: 'Moment #1', i18nLabel: t('Moment #1'), key: 'M1' },
        1: { value: 'Moment #3', i18nLabel: t('Moment #3'), key: 'M3' },
        2: { value: 'Moment #5', i18nLabel: t('Moment #5'), key: 'M5' },
    },
    uroanxiety113dipru: {
        0: { value: 'Moment #1', i18nLabel: t('Moment #1'), key: 'M1' },
        1: { value: 'Moment #3', i18nLabel: t('Moment #3'), key: 'M3' },
        2: { value: 'Moment #5', i18nLabel: t('Moment #5'), key: 'M5' },
        3: { value: 'Moment #7', i18nLabel: t('Moment #7'), key: 'M7' },
    },
    urodipru: {
        0: { value: 'Moment #2', i18nLabel: t('Moment #2'), key: 'M2' },
        1: { value: 'Moment #7', i18nLabel: t('Moment #7'), key: 'M7' },
    },
    urosocratic152sa: {
        0: { value: 'Moment #0', i18nLabel: t('Moment #0'), key: 'T0' },
        1: { value: 'Moment #6', i18nLabel: t('Moment #6'), key: 'M6' },
        2: { value: 'Moment #12', i18nLabel: t('Moment #12'), key: 'M12' },
        3: { value: 'Moment #18', i18nLabel: t('Moment #18'), key: 'M18' },
        4: { value: 'Moment #24', i18nLabel: t('Moment #24'), key: 'M24' },
        5: { value: 'Moment #30', i18nLabel: t('Moment #30'), key: 'M30' },
        6: { value: 'Moment #36', i18nLabel: t('Moment #36'), key: 'M36' },
        7: { value: 'Moment #48', i18nLabel: t('Moment #48'), key: 'M48' },
        8: { value: 'Moment #60', i18nLabel: t('Moment #60'), key: 'M60' },
    },
    urosocratic152sur: {
        0: { value: 'Moment #0', i18nLabel: t('Moment #0'), key: 'T0' },
        1: { value: 'Moment #12', i18nLabel: t('Moment #12'), key: 'M12' },
        2: { value: 'Moment #24', i18nLabel: t('Moment #24'), key: 'M24' },
        3: { value: 'Moment #36', i18nLabel: t('Moment #36'), key: 'M36' },
        4: { value: 'Moment #48', i18nLabel: t('Moment #48'), key: 'M48' },
        5: { value: 'Moment #60', i18nLabel: t('Moment #60'), key: 'M60' },
    },
    uropersonalize114: {
        0: { value: 'T1', i18nLabel: t('T1'), key: 'T1' },
        1: { value: 'T3', i18nLabel: t('T3'), key: 'T3' },
        2: { value: 'T6', i18nLabel: t('T6'), key: 'T6' },
    },
}

export const CARDS_MEDICAL_MOMENT_STUDIES = {
    0: {
        value: 'GOHAI',
        i18nLabel: t('GOHAI'),
        key: 'GOHAI',
        filter: {
            curve: ['M1', 'M2', 'M3'],
            urorein: ['M1', 'M2', 'M3'],
            uroreinambu: ['M1', 'M2', 'M3'],
            uroprostate: ['M1', 'M2', 'M3'],
            urovessie: ['M1', 'M2', 'M3'],
        },
    },
    1: {
        value: 'QLQ C30',
        i18nLabel: t('QLQ C30'),
        key: 'QLQ C30',
        filter: {
            curve: ['M1', 'M2', 'M3'],
            urorein: ['M1', 'M2', 'M3'],
            uroreinambu: ['M1', 'M2', 'M3'],
            uroprostate: ['M1', 'M2', 'M3'],
            urovessie: ['M1', 'M2', 'M3'],
        },
    },
    2: {
        value: 'QLQ H&N35',
        i18nLabel: t('QLQ H&N35'),
        key: 'QLQ H&N35',
        filter: {
            curve: ['M1', 'M2', 'M3'],
            urorein: ['M1', 'M2', 'M3'],
            uroreinambu: ['M1', 'M2', 'M3'],
            uroprostate: ['M1', 'M2', 'M3'],
            urovessie: ['M1', 'M2', 'M3'],
        },
    },
    3: {
        value: 'MDADI',
        i18nLabel: t('MDADI'),
        key: 'MDADI',
        filter: {
            curve: ['M3'],
            urorein: ['M3'],
            uroreinambu: ['M3'],
            uroprostate: ['M3'],
            urovessie: ['M3'],
        },
    },
    4: {
        value: 'SHI',
        i18nLabel: t('SHI'),
        key: 'SHI',
        filter: {
            curve: ['M3'],
            urorein: ['M3'],
            uroreinambu: ['M3'],
            uroprostate: ['M3'],
            urovessie: ['M3'],
        },
    },
    5: {
        value: 'VAS pain-aesthetic',
        i18nLabel: t('VAS pain-aesthetic'),
        key: 'VAS pain-aesthetic',
        filter: {
            curve: ['M3'],
            urorein: ['M3'],
            uroreinambu: ['M3'],
            uroprostate: ['M3'],
            urovessie: ['M3'],
        },
    },
    6: {
        value: 'HADS',
        i18nLabel: t('HADS'),
        key: 'HADS',
        filter: {
            less: ['M1', 'M2', 'M3', 'M4', 'M5'],
            urosocratic152sa: [
                'M0',
                'M6',
                'M12',
                'M18',
                'M24',
                'M30',
                'M36',
                'M48',
                'M60',
            ],
            urosocratic152sur: [
                'M0',
                'M6',
                'M12',
                'M18',
                'M24',
                'M30',
                'M36',
                'M48',
                'M60',
            ],
        },
    },
    7: {
        value: 'IOCv2',
        i18nLabel: t('IOCv2'),
        key: 'IOCv2',
        filter: {
            less: ['M1', 'M2', 'M3', 'M4', 'M5'],
        },
    },
    8: {
        value: 'QLQBR23',
        i18nLabel: t('QLQBR23'),
        key: 'QLQBR23',
        filter: {
            less: ['M1', 'M2', 'M3', 'M4', 'M5'],
        },
    },
    9: {
        value: 'QLQ C30',
        i18nLabel: t('QLQ C30'),
        key: 'QLQ C30',
        filter: {
            less: ['M1', 'M2', 'M3', 'M4', 'M5'],
        },
    },
    10: {
        value: 'QLQFA12',
        i18nLabel: t('QLQFA12'),
        key: 'QLQFA12',
        filter: {
            less: ['M1', 'M2', 'M3', 'M4', 'M5'],
        },
    },
    11: {
        value: 'QLQFACTCOG',
        i18nLabel: t('QLQFACTCOG'),
        key: 'QLQFACTCOG',
        filter: {
            less: ['M1', 'M2', 'M3', 'M4', 'M5'],
        },
    },
    12: {
        value: 'EQ5D5L',
        i18nLabel: t('EQ5D5L'),
        key: 'EQ5D5L',
        filter: {
            uroaccurate99: ['M0', 'M1', 'M3', 'M5', 'M12'],
        },
    },
    13: {
        value: 'STAI',
        i18nLabel: t('STAI'),
        key: 'STAI',
        filter: {
            urodipru: ['M1', 'M2', 'M3', 'M5'],
            uroanxiety113dipru: ['M1', 'M2', 'M3', 'M5'],
            uroanxiety113: ['M1', 'M2', 'M3', 'M5'],
        },
    },
    14: {
        value: 'EQ5D5L',
        i18nLabel: t('EQ5D5L'),
        key: 'EQ5D5L',
        filter: {
            uroanxiety113dipru: ['M1', 'M5'],
            uroanxiety113: ['M1', 'M5'],
            urosocratic152sa: [
                'M0',
                'M6',
                'M12',
                'M18',
                'M24',
                'M30',
                'M36',
                'M48',
                'M60',
            ],
            urosocratic152sur: [
                'M0',
                'M6',
                'M12',
                'M18',
                'M24',
                'M30',
                'M36',
                'M48',
                'M60',
            ],
        },
    },
    15: {
        value: 'HLSEUQ16',
        i18nLabel: t('HLSEUQ16'),
        key: 'HLSEUQ16',
        filter: {
            uroanxiety113dipru: ['M1', 'M5'],
            uroanxiety113: ['M1', 'M5'],
        },
    },
    16: {
        value: 'WAKE',
        i18nLabel: t('WAKE'),
        key: 'WAKE',
        filter: {
            uroanxiety113dipru: ['M3'],
            uroanxiety113: ['M3'],
        },
    },
    17: {
        value: 'E-SATIS',
        i18nLabel: t('E-SATIS'),
        key: 'ESATIS',
        filter: {
            uroanxiety113dipru: ['M7'],
            urodipru: ['M7'],
        },
    },
    18: {
        value: 'SF12',
        i18nLabel: t('SF12'),
        key: 'SF12',
        filter: {
            urosocratic152sa: [
                'M0',
                'M6',
                'M12',
                'M18',
                'M24',
                'M30',
                'M36',
                'M48',
                'M60',
            ],
            urosocratic152sur: [
                'M0',
                'M6',
                'M12',
                'M18',
                'M24',
                'M30',
                'M36',
                'M48',
                'M60',
            ],
        },
    },
    ///
    19: {
        value: 'Connaissances Personalize 114',
        i18nLabel: t('Connaissances Personalize 114'),
        key: 'KNOWLEDGE',
        filter: {
            uropersonalize114: ['T1', 'T3', 'T6'],
        },
    },
    20: {
        value: 'HLSEUQ16 Personalize 114',
        i18nLabel: t('HLSEUQ16 Personalize 114'),
        key: 'HLSEUQ16',
        filter: {
            uropersonalize114: ['T1', 'T3', 'T6'],
        },
    },
    21: {
        value: 'Satisfaction Personalize 114',
        i18nLabel: t('Satisfaction Personalize 114'),
        key: 'SATISFACTION',
        filter: {
            uropersonalize114: ['T1', 'T3', 'T6'],
        },
    },
    //
    22: {
        value: 'J-1',
        i18nLabel: t('J-1'),
        key: 'J-1',
        filter: { 'J-1': ['J-1'] },
    },
    23: {
        value: 'J+1',
        i18nLabel: t('J+1'),
        key: 'J+1',
        filter: { 'J+1': ['J+1'] },
    },
    24: {
        value: 'J+2',
        i18nLabel: t('J+2'),
        key: 'J+2',
        filter: { 'J+2': ['J+2'] },
    },
    25: {
        value: 'J+3',
        i18nLabel: t('J+3'),
        key: 'J+3',
        filter: { 'J+3': ['J+3'] },
    },
    26: {
        value: 'J+6',
        i18nLabel: t('J+6'),
        key: 'J+6',
        filter: { 'J+6': ['J+6'] },
    },
    27: {
        value: 'J+7',
        i18nLabel: t('J+7'),
        key: 'J+7',
        filter: { 'J+7': ['J+7'] },
    },
    28: {
        value: 'J+9',
        i18nLabel: t('J+9'),
        key: 'J+9',
        filter: { 'J+9': ['J+9'] },
    },
    29: {
        value: 'J+14',
        i18nLabel: t('J+14'),
        key: 'J+14',
        filter: { 'J+14': ['J+14'] },
    },
    30: {
        value: 'J+21',
        i18nLabel: t('J+21'),
        key: 'J+21',
        filter: { 'J+21': ['J+21'] },
    },
    31: {
        value: 'J+30',
        i18nLabel: t('J+30'),
        key: 'J+30',
        filter: { 'J+30': ['J+30'] },
    },
}

export const ALERTS_TYPES = {
    0: { theme: '', value: '', key: 0, color: '' },
    1: {
        theme: 'challenge',
        value: 'Défi',
        i18nLabel: t('Défi'),
        key: 1,
        color: 'FF6F41',
        order: 1,
    },
    2: {
        theme: 'program',
        value: 'Programme',
        i18nLabel: t('Programme'),
        key: 3,
        color: '049588',
        order: 2,
    },
}
export const CARDS_HELLO_TYPES = {
    1: { value: 'Bonjour', key: 1 },
    2: { value: 'Contexte', key: 2 },
    3: { value: 'Infos', key: 3 },
    4: { value: 'Salutation', key: 4 },
}

export const CARDS_STATUT = {
    1: { value: 'Activée', i18nValue: t('Activée'), key: '1', colorKey: '1' },
    2: {
        value: 'Activée, à vérifier',
        i18nValue: t('Activée, à vérifier'),
        key: '2',
        colorKey: '2',
    },
    3: {
        value: 'Désactivée',
        i18nValue: t('Désactivée'),
        key: '0',
        colorKey: '0',
    },
}

export const CARDS_STATUT_NOTADMIN = {
    1: { value: 'Activée', key: '1', colorKey: '1' },
    2: { value: 'Activée', key: '2', colorKey: '1' },
    3: { value: 'Désactivée', key: '0', colorKey: '0' },
}

export const TASKS_STATUT = {
    1: { value: 'Activée', key: '1', colorKey: '1' },
    2: { value: 'Activée', key: '2', colorKey: '1' },
    3: { value: 'Désactivée', key: '0', colorKey: '0' },
}

export const CONFIG_PROGRAM_CARDS = {
    1: { label: 'Programme', i18nLabel: t('Programme'), type: 'programs' },
    2: { label: 'Séances', i18nLabel: t('Séances'), type: 'sessions' },
    3: { label: 'Étapes', i18nLabel: t('Étapes'), type: 'steps' },
}

export const ALERTS_CREATION = {
    0: {
        label: 'Défi',
        i18nLabel: t('Défi'),
        infos: ['Alerte lié à un défi'],
        i18nInfos: [t('Alerte lié à un défi')],
        type: 1,
        template: 'alert-challenge',
        addurl: 'private.alerts.edit',
    },
    1: {
        label: 'Programme',
        i18nLabel: t('Programme'),
        infos: ['Alerte liée à un programme'],
        i18nInfos: [t('Alerte liée à un programme')],
        type: 3,
        template: 'alert-program',
        addurl: 'private.alerts.edit',
    },
}
export const CARDS_CREATION = {
    0: {
        label: 'Alfred',
        infos: ['Illustration', '1 par jour'],
        i18nInfos: [t('Illustration'), t('1 par jour')],
        type: 13,
        template: 'alfred_info',
        name: 'alfred',
        addurl: 'private.cards.edit',
    },
    1: {
        label: 'Article',
        infos: ['Sujet de fond', '1 par jour', 'Entre 1000 et 2000 caractères'],
        i18nInfos: [t('Sujet de fond'), t('Entre 1000 et 2000 caractères')],
        type: 18,
        template: 'article',
        name: 'article',
        addurl: 'private.cards.edit',
    },
    2: {
        label: 'Bandeau Image',
        infos: ['Illustration', '1 par jour', '1280x300px'],
        type: 15,
        template: 'bandeau_image',
        name: '',
        addurl: 'private.cards.edit',
    },
    3: {
        label: 'Bonjour',
        infos: [
            'Carte d‘accueil / info',
            'Découpé en 4 blocs KPIsable',
            'Bonjour, contexte, remontée, salutations',
        ],
        i18nInfos: [
            t('Carte d‘accueil / info'),
            t('Découpé en 4 blocs KPIsable'),
            t('Bonjour, contexte, remontée, salutations'),
        ],
        type: 16,
        template: 'hello',
        name: 'hello',
        addurl: 'private.cards.edit',
    },
    4: {
        label: 'Citation',
        infos: [
            'Phrase de motivation',
            '1 par jour',
            'Nombre de caractères limité',
        ],
        i18nInfos: [
            t('Phrase de motivation'),
            t('Nombre de caractères limité'),
        ],
        type: 20,
        template: 'citation',
        name: 'citation',
        addurl: 'private.cards.edit',
    },
    5: {
        label: 'Conseil',
        infos: [
            'Fortement personnalisé',
            '3 par jour',
            'Entre 400 et 500 caractères',
        ],
        i18nInfos: [
            t('Fortement personnalisé'),
            t('3 par jour'),
            t('Entre 400 et 500 caractères'),
        ],
        type: 2,
        template: 'accompagnement_generique',
        name: 'advice',
        addurl: 'private.cards.edit',
    },
    6: {
        label: 'Coup de pouce',
        infos: [],
        type: 54,
        template: 'coup_de_pouce',
        name: '',
        addurl: 'private.cards.edit',
    },
    7: {
        label: 'Dashboard',
        infos: [
            'Analyse de la donnée quantitative',
            'Accompagne les graphiques',
            'Entre 300 et 400 caractères',
        ],
        i18nInfos: [
            t('Analyse de la donnée quantitative'),
            t('Accompagne les graphiques'),
            t('Entre 300 et 400 caractères'),
        ],
        type: 17,
        template: 'dashboard_info',
        name: 'dashboard',
        addurl: 'private.cards.edit',
    },
    8: {
        label: 'Défi',
        infos: ['Cap ou pas cap', 'Défi simple', 'Nombre de caractères limité'],
        i18nInfos: [
            t('Cap ou pas cap'),
            t('Défi simple'),
            t('Nombre de caractères limité'),
        ],
        type: 14,
        template: 'defis',
        name: 'challenge',
        addurl: 'private.cards.edit',
    },
    9: {
        label: 'Évaluation classique',
        infos: [
            'Question / collecte de données',
            '3 par jour',
            'Plusieurs types possibles',
        ],
        i18nInfos: [
            t('Question / collecte de données'),
            t('Plusieurs types possibles'),
        ],
        type: 1,
        template: 'evaluation_generique',
        name: 'eval classic',
        addurl: 'private.cards.edit',
    },
    10: {
        label: 'Évaluation comportement',
        infos: [
            'Question / collecte de données',
            '4 par jour',
            'Plusieurs types possibles',
        ],
        i18nInfos: [t('4 par jour')],
        type: 22,
        template: 'evaluation_comportement',
        name: 'eval comportement',
        addurl: 'private.cards.edit',
    },
    11: {
        label: 'Évaluation questionnaire',
        infos: ['Question / collecte de données', 'Plusieurs types possibles'],
        type: 58,
        template: 'evaluation_survey',
        name: 'eval questionnaire',
        addurl: 'private.cards.edit',
    },
    12: {
        label: 'Mail Hebdo',
        infos: [
            'Mail d‘accueil / info',
            'Découpé en 4 blocs KPIsable',
            'Bonjour, contexte, remontée, salutations',
        ],
        i18nInfos: [
            t('Mail d‘accueil / info'),
            t('Découpé en 4 blocs KPIsable'),
            t('Bonjour, contexte, remontée, salutations'),
        ],
        type: 7,
        template: 'mail_hebdo',
        name: 'hebdo',
        addurl: 'private.cards.edit',
    },
    13: {
        label: 'Partenaire',
        infos: ['Illutration', 'Lien cliquable', 'Accompagne le parrainage'],
        i18nInfos: [t('Lien cliquable'), t('Accompagne le parrainage')],
        type: 24,
        template: 'partenaire',
        name: '',
        addurl: 'private.cards.edit',
    },
    14: {
        label: 'Programme',
        infos: [
            'Objectif précis',
            'Construction mobile',
            'Privilégier le média vidéo',
        ],
        i18nInfos: [
            t('Objectif précis'),
            t('Construction mobile'),
            t('Privilégier le média vidéo'),
        ],
        type: 6,
        template: 'program',
        name: 'program',
        addurl: 'private.cards.edit',
    },
    15: {
        label: 'Quiz',
        infos: ['Vrai ou faux', '1 par jour', 'Nombre de caractères limité'],
        i18nInfos: [t('Vrai ou faux'), t('Nombre de caractères limité')],
        type: 12,
        template: 'quizz',
        name: 'quizz',
        addurl: 'private.cards.edit',
    },
    16: {
        label: 'Recette',
        infos: ['Semaine et week-end', '1 par jour', 'Variante possible'],
        i18nInfos: [t('Semaine et week-end'), t('Variante possible')],
        type: 19,
        template: 'recipe',
        name: 'recipe',
        addurl: 'private.cards.edit',
    },
}

export const STEPS_CREATION = {
    0: {
        label: 'Objectif',
        infos: ['Objectif de séance'],
        i18nInfos: [t('Objectif de séance')],
        type: 1,
        template: 'objectif',
        name: 'objectif',
    },
    1: {
        label: 'Évaluation',
        infos: ['Évaluation de séance'],
        i18nInfos: [t('Évaluation de séance')],
        type: 2,
        template: 'evaluation_generique',
        name: 'evaluation_generique',
    },
    2: {
        label: 'Exercice',
        infos: ['Exercice durant la séance'],
        i18nInfos: [t('Exercice durant la séance')],
        type: 3,
        template: 'exercice',
        name: 'exercice',
    },
    3: {
        label: 'Conseil',
        infos: ['Conseil au cours de la séance'],
        i18nInfos: [t('Conseil au cours de la séance')],
        type: 4,
        template: 'advice',
        name: 'advice',
    },
}

export const CONFIG_DOWNLOADS = {
    0: {
        label: 'de traductions',
        key: 'translations',
        availableType: false,
        availableLang: true,
        availableId: false,
        directLink: true,
        defaultType: '',
        defaultLang: {
            label: 'Anglais',
            key: 'en',
        },
        defaultId: '',
    },
    1: {
        label: 'de cartes',
        key: 'cards',
        availableType: true,
        availableLang: true,
        availableId: false,
        directLink: true,
        defaultType: {
            label: 'Bonjour',
            key: '16',
        },
        defaultLang: {
            label: 'Français',
            key: 'fr',
        },
        defaultId: '',
        typeList: CARDS_CREATION,
    },
    2: {
        label: 'd‘articles',
        key: 'articles',
        availableType: false,
        availableLang: true,
        availableId: false,
        directLink: true,
        defaultType: '',
        defaultLang: {
            label: 'Français',
            key: 'fr',
        },
        defaultId: '',
    },
    3: {
        label: 'de recettes',
        key: 'recipes',
        availableType: false,
        availableLang: true,
        availableId: false,
        directLink: true,
        defaultType: '',
        defaultLang: {
            label: 'Français',
            key: 'fr',
        },
        defaultId: '',
    },
    4: {
        label: 'de défis',
        key: 'challenges',
        availableType: false,
        availableLang: true,
        availableId: false,
        directLink: true,
        defaultType: '',
        defaultLang: {
            label: 'Français',
            key: 'fr',
        },
        defaultId: '',
    },
    5: {
        label: 'de programmes',
        key: 'program',
        availableType: false,
        availableLang: true,
        availableId: true,
        directLink: true,
        defaultType: '',
        defaultLang: {
            label: 'Français',
            key: 'fr',
        },
        defaultId: {
            label: '',
            key: '',
        },
        typeList: CONFIG_PROGRAM_CARDS,
    },
}

export const CONFIG_MESSAGE_TYPES = {
    0: { value: 'Information', i18nValue: t('Information'), key: '0' },
    1: { value: 'Alerte', i18nValue: t('Alerte'), key: '1' },
}

export const CONFIG_HELLO_CARDS = {
    1: { value: 'Bonjour', key: '1' },
    2: { value: 'Contexte', key: '2' },
    3: { value: 'Infos', key: '3' },
    4: { value: 'Salutation', key: '4' },
}

export const CONFIG_DOWNLOADS_LANGS = {
    0: { value: 'Français', key: 'fr' },
    1: { value: 'Anglais', key: 'en' },
    2: { value: 'Espagnol', key: 'es' },
    3: { value: 'Italien', key: 'it' },
    4: { value: 'Allemand', key: 'de' },
    5: { value: 'Pays-Bas', key: 'nl' },
}

import icoFR from '@app/img/ico/flag/flag-fr.png'
import icoFRx2 from '@app/img/ico/flag/flag-fr@2x.png'
import icoEN from '@app/img/ico/flag/flag-en.png'
import icoENx2 from '@app/img/ico/flag/flag-en@2x.png'
import icoES from '@app/img/ico/flag/flag-es.png'
import icoESx2 from '@app/img/ico/flag/flag-es@2x.png'
import icoDE from '@app/img/ico/flag/flag-de.png'
import icoDEx2 from '@app/img/ico/flag/flag-de@2x.png'
import icoIT from '@app/img/ico/flag/flag-it.png'
import icoITx2 from '@app/img/ico/flag/flag-it@2x.png'
import icoNL from '@app/img/ico/flag/flag-nl.png'
import icoNLx2 from '@app/img/ico/flag/flag-nl@2x.png'

export const COUNTRIES = {
    0: {
        label: 'Français',
        i18nLabel: t('Français'),
        key: 'fr',
        flag: { normal: icoFR, retina: icoFRx2 },
    },
    1: {
        label: 'Anglais',
        i18nLabel: t('Anglais'),
        key: 'en',
        flag: { normal: icoEN, retina: icoENx2 },
    },
    2: {
        label: 'Espagnol',
        i18nLabel: t('Espagnol'),
        key: 'es',
        flag: { normal: icoES, retina: icoESx2 },
    },
    3: {
        label: 'Allemand',
        i18nLabel: t('Allemand'),
        key: 'de',
        flag: { normal: icoDE, retina: icoDEx2 },
    },
    4: {
        label: 'Italien',
        i18nLabel: t('Italien'),
        key: 'it',
        flag: { normal: icoIT, retina: icoITx2 },
    },
    5: {
        label: 'Pays-Bas',
        i18nLabel: t('Pays-Bas'),
        key: 'nl',
        flag: { normal: icoNL, retina: icoNLx2 },
    },
    6: {
        label: 'Français (US)',
        i18nLabel: t('Pays-Bas (US)'),
        key: 'fr-US',
        flag: { normal: icoFR, retina: icoFRx2 },
    },
    7: {
        label: 'Pays-Bas (US)',
        i18nLabel: t('Pays-Bas (US)'),
        key: 'nl-US',
        flag: { normal: icoNL, retina: icoNLx2 },
    },
    8: {
        label: 'Anglais (US)',
        i18nLabel: t('Anglais (US)'),
        key: 'en-US',
        flag: { normal: icoEN, retina: icoENx2 },
    },
    9: {
        label: 'Espagnol (US)',
        i18nLabel: t('Espagnol (US)'),
        key: 'es-US',
        flag: { normal: icoES, retina: icoESx2 },
    },
    10: {
        label: 'Italien (US)',
        i18nLabel: t('Italien (US)'),
        key: 'it-US',
        flag: { normal: icoIT, retina: icoITx2 },
    },
    11: {
        label: 'Allemand (US)',
        i18nLabel: t('Allemand (US)'),
        key: 'de-US',
        flag: { normal: icoDE, retina: icoDEx2 },
    },
}

export const EVAL_TYPES = {
    0: { key: 'Slider', value: 'Slider', type: 1 },
    1: { key: 'Button', value: 'Button', type: 2 },
    2: { key: 'QCM', value: 'QCM', type: 3 },
    3: { key: 'Textarea', value: 'Textarea', type: 4 },
    4: { key: 'Counter', value: 'Compteur', type: 5 },
}

export const REDIRECT_URL = {
    0: {
        value: 'Page Programmes',
        i18nValue: t('Page Programmes'),
        key: 'PROGRAMS',
    },
    1: {
        value: 'Page Objectifs',
        i18nValue: t('Page Objectifs'),
        key: 'OBJECTIVES',
    },
    2: { value: 'Page Profil', i18nValue: t('Page Profil'), key: 'PROFIL' },
    3: { value: 'Page OC', i18nValue: t('Page OC'), key: 'OC' },
    4: { value: 'Page Données', i18nValue: t('Page Données'), key: 'DATAS' },
    5: { value: 'Page Badges', i18nValue: t('Page Badges'), key: 'BADGES' },
    6: {
        value: 'Page Nutrition',
        i18nValue: t('Page Nutrition'),
        key: 'NUTRITION',
    },
    7: {
        value: 'Page Favoris',
        i18nValue: t('Page Favoris'),
        key: 'FAVORITES',
    },
    8: {
        value: 'Page Traitement',
        i18nValue: t('Page Traitement'),
        key: 'TREATMENT',
    },
    9: {
        value: 'Page Glycémies',
        i18nValue: t('Page Glycémies'),
        key: 'GLYCEMY',
    },
    10: {
        value: 'Page Actions Solidarités',
        i18nValue: t('Page Actions Solidarités'),
        key: 'ACTIONS_SOLIDARITY',
    },
    11: {
        value: 'Page TVB, c‘est quoi ?',
        i18nValue: t('Page TVB, c‘est quoi ?'),
        key: 'WHAT_IS_TVB',
    },
    12: { value: 'Lien', i18nValue: t('Lien'), key: '' },
}

export const MEDICALSTUDYALERTCOLOR = {
    0: { key: '', value: 'Automatique' },
    1: { key: 0, value: 'Vert' },
    2: { key: 1, value: 'Vert foncé' },
    3: { key: 2, value: 'Jaune' },
    4: { key: 3, value: 'Orange' },
    5: { key: 4, value: 'Rouge' },
}

export const PHONE_PREFIX = {
    0: { key: '+1', value: '+1 (usa / canada)' },
    1: { key: '+31', value: '+31 (pays bas)' },
    2: { key: '+32', value: '+32 (belgique)' },
    3: { key: '+33', value: '+33 (france)' },
    4: { key: '+34', value: '+34 (espagne)' },
    5: { key: '+39', value: '+39 (italie)' },
    6: { key: '+41', value: '+41 (suisse)' },
    7: { key: '+44', value: '+44 (royaume-uni)' },
    8: { key: '+49', value: '+49 (allemagne)' },
    9: { key: '+212', value: '+212 (maroc)' },
    10: { key: '+213', value: '+213 (algérie)' },
    11: { key: '+216', value: '+216 (tunisie)' },
    12: { key: '+218', value: '+218 (lybie)' },
    13: { key: '+222', value: '+222 (mauritanie)' },
    14: { key: '+262', value: '+262 (la réunion)' },
    15: { key: '+269', value: '+269 (comores)' },
    16: { key: '+351', value: '+351 (portugal)' },
    17: { key: '+352', value: '+352 (luxembourg)' },
    18: { key: '+353', value: '+353 (irlande)' },
    19: { key: '+590', value: '+590 (guadeloupe)' },
    20: { key: '+594', value: '+594 (guinée française)' },
    21: { key: '+596', value: '+596 (martinique)' },
    22: { key: '+687', value: '+687 (nouvelle calédonie)' },
    23: { key: '+689', value: '+689 (polynésie française)' },
}
